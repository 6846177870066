import { getSiteConfig } from './getAuthData'

// 站点SEO数据
export const SITE_SEO_LIST = [
  {
    key: 'nx',
    title: '宁夏技能人才综合管理服务平台',
    keywords:
      '宁夏技能人才综合管理服务平台,宁夏技能人才,宁夏考评,职业技能等级认定,职业技能鉴定,专项职业能力考核,职业技能竞赛,职业技能等级证书查询,职业技能等级认定监管平台,题库管理系统,智能监控系统,wets,沃考评,等级评价无纸化考试',
    description:
      '宁夏技能人才综合管理服务平台由宁夏回族自治区职业技能鉴定指导中心主办，杭州沃土教育科技股份有限公司提供技术支持。实现全区技能人才评价数字化管理，推动业务信息线上录入、业务流程线上管理、业务活动线上开展、业务质量线上考核，以加强对技能人才评价的全面、实时、动态监督管理，加强技工院校管理，加强信息共享和协作配合，规范各地各行业等级评价行为，增强市场化人才评价能力，提高技能人才评价质量和效率，推动全区技能人才评价工作科学发展。',
  },
  {
    key: 'gz',
    title: '贵州技能人才评价管理服务平台',
    keywords:
      '贵州技能人才评价管理服务平台,贵州职业技能等级认定题库管理服务平台,贵州技能人才,贵州人才评价,职业技能等级认定,职业技能鉴定,专项职业能力考核,职业技能竞赛,职业技能等级证书查询,职业技能等级认定监管平台,智能监控系统,wets,沃考评,等级评价无纸化考试',
    description:
      '贵州技能人才评价管理服务平台由贵州省职业技能鉴定考评指导中心主办，杭州沃土教育科技股份有限公司提供技术支持。市场主体、政府监管、共建共享、协同发力的管理原则，建设贵州省全省统一的题库管理服务平台，协助甲方推进全省题库资源征集管理工作，以题库管理服务平台为桥梁，链接各级人社主管部门、题库资源方、技能专家、评价机构、第三方考务系统厂家等主体，进一步建立权责清晰、运转高效、管理科学、质量可靠的管理服务体系',
  },
  {
    key: 'zj',
    title: '浙江省职业技能等级认定管理服务平台',
    keywords:
      '浙江省职业技能等级认定管理服务平台,浙江技能人才,浙江人才评价,职业技能等级认定,职业技能鉴定,专项职业能力考核,职业技能竞赛,职业技能等级证书查询,职业技能等级认定监管平台,题库管理系统,智能监控系统,wets,沃考评,等级评价无纸化考试',
    description:
      '浙江省职业技能等级认定管理服务平台由杭州沃土教育科技股份有限公司提供技术支持。提供包含评价机构管理、技能人次评价专家管理、评价计划管理、考务安排、考场编排、考生报名、实操电子化打分、监控中心、无纸化考试、自有题库管理等技能评价业务闭环',
  },
  {
    key: 'jiangxidjrd',
    title: '江西省职业技能等级认定线上考试服务平台',
    keywords:
      '江西省职业技能等级认定线上考试服务平台,江西考评,江西技能人才,职业技能等级认定,职业技能鉴定,专项职业能力考核,职业技能竞赛,职业技能等级证书查询,职业技能等级认定监管平台,题库管理系统,智能监控系统,wets,沃考评,等级评价无纸化考试',
    description:
      '江西省职业技能等级认定线上考试服务平台由江西省人力资源和社会保障厅主办，杭州沃土教育科技股份有限公司提供技术支持。提供包含评价机构管理、技能人次评价专家管理、评价计划管理、考务安排、考场编排、考生报名、实操电子化打分、监控中心、无纸化考试、自有题库管理等技能评价业务闭环',
  },
  {
    key: 'jx',
    title: '江西省职业技能等级认定线上考试服务平台',
    keywords:
      '江西省职业技能等级认定线上考试服务平台,江西考评,江西技能人才,职业技能等级认定,职业技能鉴定,专项职业能力考核,职业技能竞赛,职业技能等级证书查询,职业技能等级认定监管平台,题库管理系统,智能监控系统,wets,沃考评,等级评价无纸化考试',
    description:
      '江西省职业技能等级认定线上考试服务平台由江西省人力资源和社会保障厅主办，杭州沃土教育科技股份有限公司提供技术支持。提供包含评价机构管理、技能人次评价专家管理、评价计划管理、考务安排、考场编排、考生报名、实操电子化打分、监控中心、无纸化考试、自有题库管理等技能评价业务闭环',
  },
  {
    key: 'hz',
    title: '杭州市职业技能等级认定管理服务平台',
    keywords:
      '杭州市职业技能等级认定管理服务平台,杭州技能评价,杭州考评,杭州人才评价,职业技能等级认定,职业技能鉴定,专项职业能力考核,职业技能竞赛,职业技能等级证书查询,职业技能等级认定监管平台,题库管理系统,智能监控系统,wets,沃考评,等级评价无纸化考试',
    description:
      '杭州市职业技能等级认定管理服务平台由杭州市人力资源和社会保障局主办，由杭州沃土教育科技股份有限公司提供技术支持。根据杭州市职业技能人才评价业务开展需要，针对性提供技术保障服务，实现全市技能人才评价数字化管理，探索职业技能等级认定题库建设、无纸化考试、认定过程监督管理、技能竞赛数字化管理体系建设，确保我市职业技能等级认定工作规范有序开展',
  },
  {
    key: 'sc',
    title: '四川省职业技能等级评价题库管理服务平台',
    keywords:
      '四川省职业技能等级评价题库管理服务平台,四川考评,四川技能鉴定,四川人才评价,职业技能等级认定,职业技能鉴定,专项职业能力考核,职业技能竞赛,职业技能等级证书查询,职业技能等级认定监管平台,题库管理系统,智能监控系统,wets,沃考评,等级评价无纸化考试',
    description:
      '四川省职业技能等级评价题库管理服务平台由四川省职业技能鉴定指导中心主办，杭州沃土教育科技股份有限公司提供技术支持。市场主体、政府监管、共建共享、协同发力的管理原则，建设贵州省全省统一的题库管理服务平台，协助甲方推进全省题库资源征集管理工作，以题库管理服务平台为桥梁，链接各级人社主管部门、题库资源方、技能专家、评价机构、第三方考务系统厂家等主体，进一步建立权责清晰、运转高效、管理科学、质量可靠的管理服务体系',
  },
]
export function isKeywords() {
  return document.querySelector('meta[name="keywords"]')
}
export function isDescription() {
  return document.querySelector('meta[name="description"]')
}

export function removeSiteSeo() {
  const ele_keywords = isKeywords()
  const ele_description = isDescription()
  if (ele_keywords) {
    ele_keywords.remove()
  }
  if (ele_description) {
    ele_description.remove()
  }
}
/**
 * 设置站点seo信息
 * meta keywords
 * meta description
 * @returns
 */
export function setSiteSeo() {
  const { alias } = getSiteConfig() || {}
  const index = SITE_SEO_LIST.findIndex(item => alias.includes(item.key))
  if (index === -1) return

  const { keywords, description } = SITE_SEO_LIST[index] || {}
  const head = document.getElementsByTagName('head')[0]

  if (keywords && !isKeywords()) {
    const keywordMeta = document.createElement('meta')
    keywordMeta.name = 'keywords'
    keywordMeta.content = keywords
    head.appendChild(keywordMeta)
  }
  if (description && !isDescription()) {
    const descriptionMeta = document.createElement('meta')
    descriptionMeta.name = 'description'
    descriptionMeta.content = description
    head.appendChild(descriptionMeta)
  }
}
