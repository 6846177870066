import { getLocalStorage } from '@/storage'

/**
 * 获取站点信息
 * @returns
 */
export function getSiteConfig() {
  const siteConfig = getLocalStorage('SITE_CONFIG')
  return siteConfig
}
