import { getSiteConfig } from './getAuthData'

export default (title?: string) => {
  const { config } = getSiteConfig() || {}
  const { wap_logo, pc_title } = config || {}
  if (!document.querySelector("link[rel*='icon']")) {
    // 获取头部并创建一个新的link元素
    const head = document.getElementsByTagName('head')[0]
    const link = document.createElement('link')
    // 设置属性
    link.type = 'image/x-icon'
    link.rel = 'shortcut icon'
    link.href = wap_logo
    // 将元素添加至头部
    head.appendChild(link)
  }
  if (title) {
    document.title = `${title}-${pc_title}`
  } else {
    document.title = pc_title
  }
}
