import Cookies from 'js-cookie'

/**
 * 默认过期时间
 */
const DEFAULT_EXPIRES = 1
const DEFAULT_DOMAIN = location?.hostname

export enum KEYS {}

/**
 * 获取 cookie
 * @param key
 * @returns
 */
export const getCookie = (key: keyof typeof KEYS) => {
  const res = Cookies.get(KEYS[key]) as any
  try {
    return JSON.parse(res)
  } catch (e) {
    return res
  }
}

/**
 * 写入 cookie
 * @param key
 * @param data
 * @param expires
 */
export const setCookie = (
  key: keyof typeof KEYS,
  data: any,
  expires: number = DEFAULT_EXPIRES,
  domain: string = DEFAULT_DOMAIN,
) => {
  Cookies.set(KEYS[key], typeof data === 'object' ? JSON.stringify(data) : data, {
    expires,
    domain,
  })
}

/**
 * 删除
 * @param key
 */
export const delCookie = (key: keyof typeof KEYS, domain: string = DEFAULT_DOMAIN) => {
  Cookies.remove(KEYS[key], { domain })
}
