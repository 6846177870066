import { makeAutoObservable } from 'mobx'
import { getData } from '@/services/http'
import api from '@/services/http/api'
import { setLocalStorage } from '@/storage'
import type { AnyObj, SiteConfigType } from '@/types'

export default class SiteStore {
  public siteData: AnyObj | null = null

  constructor() {
    makeAutoObservable(this)
  }

  /**
   * 获取站点信息
   */
  getSiteData = async () => {
    const { data }: { data: SiteConfigType } = await getData(api.getSiteConfig)
    if (data) {
      this.updateSiteData(data)
    }
  }

  updateSiteData = (config: SiteConfigType) => {
    this.siteData = config
    setLocalStorage('SITE_CONFIG', config)
  }
}
