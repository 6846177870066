import { message } from 'antd'
import axios from 'axios'
import type { AnyObj } from '@/types'
import { getSiteConfig } from '@/utils/getAuthData'
import { transformSiteData } from "@wotu/kp-components"
import api from './api'

const getServerApi = () => {
  let serverApi = ''
  switch (BUILD_ENV) {
    case 'dev':
      serverApi = 'https://eapib.wozp.cn' // 开发
      break

    case 'test':
      serverApi = 'https://eapib.wozp.cn' // 测试
      break

    case 'pre':
      serverApi = 'https://reapib.wozp.cn' // 预发
      break

    case 'pro':
      serverApi = `https://eapib.wozhipei.com` // 正式
      break

    default:
      serverApi = 'https://eapib.wozp.cn' // 开发
      break
  }
  return serverApi
}

export const kingUrl = getServerApi()

const Http = axios.create({
  baseURL: getServerApi(),
  timeout: 80000,
})

/******
 * request拦截器==>对请求参数做处理
 * ******/
Http.interceptors.request.use(
  config => {
    const { url = '' } = config || {}
    if (url.includes('uploads')) {
      config.timeout = 600000
    } else {
      config.timeout = 8000
    }
    return config
  },
  error => {
    // 请求错误处理
    // 配置err显示
    Promise.reject(error)
  },
)

/**
 * 拦截响应response，并做一些错误处理
 */
Http.interceptors.response.use(
  response => {
    // 成功请求到数据的处理
    // 这里根据后端提供的数据进行对应的处理
    // const { config } = response;

    // if (data?.data?.data) {
    //   response.data = data.data.data
    // } else {
    //   response.data = data.data
    // }
    // if (data?.data) {
    //   response.data = data.data
    // }
    if(response.config.url === api.getSiteConfig){
      response.data = transformSiteData(response.data)
    }
    return Promise.resolve(response)
  },
  error => {
    const { response = {}, code, config = {} } = error
    const { data, status } = response
    // console.log('-------------------------------error----------------------------------')
    // console.log(response)
    // console.log(code)
    // console.log(config)
    // console.log(error.message)

    // console.log('-------------------------------response----------------------------------')
    // console.log(data)
    // console.log(status)

    if (code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
      message.error('请求超时,请稍后再试!')
      return Promise.reject('请求超时,请稍后再试!')
    }
    if (axios.isCancel(error)) {
      return {}
    }
    if (status === undefined) {
      return Promise.reject({
        message: '数据有误，请联系管理员',
        data: { message: '数据有误，请联系管理员' },
      })
    }

    // const { location } = history || {};
    // const { pathname, search } = location || {};
    switch (Number(status || 0)) {
      case 400:
        break
      case 401:
        return Promise.reject(error.response)
      case 403:
        break
      case 404:
        break
      default:
    }
    if (!config.noMsg) {
      let msg = ''
      if (Array.isArray(data)) {
        msg = data[0] ? data[0].message : ''
      } else {
        if (typeof data === 'object') {
          msg = data.message
        }
      }
      message.error(msg)
    }
    return Promise.reject(error.response)
  },
)

export default Http

// type BaseRequestType = (url: string, method: string, data: AnyObj, params: AnyObj, header: AnyObj, noMsg: boolean, delayTime: number, single: string) => Promise<any>

// type ConfigType = {
//   single: string
// }

// type BaseRequestType = (config: AxiosRequestConfig<AnyObj> & ConfigType) => Promise<AxiosResponse<any, any>>

type OptionType = {
  noMsg?: boolean
  delayTime?: number
  headers?: AnyObj
  single?: string
}

const baseRequest = async (
  url: string | string[],
  method = 'get',
  data = {},
  params = {},
  header: AnyObj = {},
  noMsg = true,
  delayTime = 3000,
  single?: string,
) => {
  if (window.location.hostname === 'localhost') {
    header['x-site-alias'] = 'egz'
  }

  if (url !== '/sites/config') {
    const { alias } = getSiteConfig()
    header['x-site-alias'] = alias
  }

  const myHeaders: any = {
    'content-type': 'application/json',
    ...header,
  }

  const request = Http({
    url,
    method,
    data,
    params,
    noMsg,
    delayTime,
    headers: myHeaders,
    single: single,
  } as any)
  return request
}

/**
 * get请求
 * @param urlLink 请求地址
 * @param param 请求地址
 * @param options 请求处理设置
 * @returns {Promise<AxiosResponse>}
 */
export const getData = (urlLink: string, param = {}, options: OptionType = {}) => {
  const { noMsg = false, delayTime = 3000, headers = {} } = options || {}
  return baseRequest(urlLink, 'get', {}, param, { ...headers }, noMsg, delayTime)
}

/**
 * post请求
 * @param urlLink 请求地址
 * @param param 请求地址
 * @param options 请求处理设置
 * @returns {Promise<AxiosResponse>}
 */
export const postData = (urlLink: string, param = {}, options: OptionType = {}) => {
  const { noMsg = false, delayTime = 3000, single = undefined } = options || {}
  return baseRequest(urlLink, 'post', param, {}, {}, noMsg, delayTime, single)
}

/**
 * 上传文件请求
 * @param urlLink 请求地址
 * @param param 表单数据{Object}内部必须file键值对
 * @param options 请求处理设置
 * @returns {Promise<AxiosResponse>}
 */
export const postFile = (urlLink: any, param: AnyObj = {}, options: OptionType = {}) => {
  const { noMsg = false, delayTime = 3000 } = options || {}
  const fileData = new FormData()
  for (const key in param) {
    fileData.append(key, param[key])
  }
  // 通过append向form对象添加数据
  return baseRequest(
    urlLink,
    'post',
    fileData,
    {},
    { 'Content-Type': 'multipart/form-data' },
    noMsg,
    delayTime,
  )
}

/**
 * delete请求
 * @param urlLink 请求地址
 * @param param 请求地址
 * @param options 请求处理设置
 * @returns {Promise<AxiosResponse>}
 */
export const deleteData = (urlLink: string, param = {}, options: OptionType = {}) => {
  const { noMsg = false, delayTime = 3000 } = options || {}
  return baseRequest(urlLink, 'delete', param, {}, {}, noMsg, delayTime)
}

/**
 * delete请求
 * @param urlLink 请求地址
 * @param param 请求地址
 * @param options 请求处理设置
 * @returns {Promise<AxiosResponse>}
 */
export const putData = (urlLink: string, param = {}, options: OptionType = {}) => {
  const { noMsg = false, delayTime = 3000, single = undefined } = options || {}
  return baseRequest(urlLink, 'put', param, {}, {}, noMsg, delayTime, single)
}
