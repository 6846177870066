import api from '@/services/http/api'
import { getData } from '@/services/http'
import store from '@/store'
import changeTheme from '@/utils/changeTheme'
import setDocTitle from './utils/setDocTitle'
import { setSiteSeo, removeSiteSeo } from './utils/setSiteSeo'
// import './plugins/sentry'

export const render = async (oldRender: any) => {
  let { updateSiteData } = store.siteStore
  updateSiteData = updateSiteData || new Function()

  const { status, data } = await getData(api.getSiteConfig)
  if (status === 200 && data) {
    updateSiteData(data)
    const { theme_color, theme_color2, theme_color3 } = data?.config || {}
    changeTheme({
      primaryColor: theme_color,
      btnHoverBg: theme_color2,
      auxiliaryColor1: theme_color2,
      auxiliaryColor2: theme_color3,
    })
  }
  // setSiteSeo()
  oldRender()
}

export function onRouteChange({ matchedRoutes, location }: any) {
  if (matchedRoutes.length) {
    const title = matchedRoutes[matchedRoutes.length - 1].route?.title || ''
    setDocTitle(title)
    document.body.scrollTop = document.documentElement.scrollTop = 0
  }

  if (location.pathname === '/home') {
    setSiteSeo()
  } else {
    removeSiteSeo()
  }
}
