import type { AnyObj } from '@/types'
import less from 'less'

interface themeObj {
  primaryColor: string
  btnHoverBg: string
  auxiliaryColor1: string
  auxiliaryColor2: string
}

interface colorParams {
  color: string
  alpha?: number
}

/**
 * 根据颜色生成透明色
 * */
const colorRgb = (colorParams: colorParams): string => {
  let { color, alpha } = colorParams || {}
  color = color || ''
  alpha = alpha || 0
  // 16进制颜色值的正则
  const reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/
  // 把颜色值变成小写
  let colorStr = color.toString().toLowerCase()
  if (reg.test(colorStr)) {
    // 如果只有三位的值，需变成六位，如：#fff => #ffffff
    if (colorStr.length === 4) {
      let colorNew = '#'
      for (let i = 1; i < 4; i += 1) {
        colorNew += colorStr.slice(i, i + 1).concat(colorStr.slice(i, i + 1))
      }
      colorStr = colorNew
    }
    // 处理六位的颜色值，转为RGB
    const colorChange = []
    for (let i = 1; i < 7; i += 2) {
      colorChange.push(parseInt('0x' + colorStr.slice(i, i + 2)))
    }
    colorChange.push(alpha)
    return 'RGB(' + colorChange.join(',') + ')'
  } else {
    return colorStr
  }
}

const getColorHref = () => {
  const a: AnyObj = window
  return a.publicPath + 'color.less'
}

/***编译less为css */
const getLess = async (themeColor: string) => {
  const lessData = await fetch(getColorHref())
  let lessStr = await lessData.text()
  lessStr = lessStr.replace('@primary-color: #d23e31', `@primary-color: ${themeColor}`)
  const lessScriptNode = document.createElement('script')
  lessScriptNode.src = 'https://static.zpimg.cn/public/less_2.7.3/less.min.js'
  lessScriptNode.async = true
  lessScriptNode.onload = async () => {
    const cssData = (await less.render(lessStr, {})) || {}
    const styleNode = document.createElement('style')
    styleNode.id = 'styleNode'
    styleNode.innerHTML = cssData.css
    document.body.appendChild(styleNode)
  }
  document.body.appendChild(lessScriptNode)
}

/**
 * 根据设置主题色
 * */
const changeTheme = (themeObj: themeObj): void => {
  const { primaryColor, btnHoverBg, auxiliaryColor1, auxiliaryColor2 } = themeObj || {}
  document.documentElement.style.setProperty(`--primary-color`, primaryColor)
  for (let i = 0; i <= 100; i++) {
    // 主题色透明度code命名方式：百分比去除小数点
    const percentage = String(i / 100)
    const code = percentage.replace('.', '')
    document.documentElement.style.setProperty(
      `--primary-color-${code}`,
      colorRgb({ color: primaryColor, alpha: i / 100 }),
    )
  }
  document.documentElement.style.setProperty(`--btn-primary-bg`, primaryColor)
  document.documentElement.style.setProperty(`--my-btn-hover-bg`, btnHoverBg)
  document.documentElement.style.setProperty(`--auxiliary-color-1`, auxiliaryColor1)
  document.documentElement.style.setProperty(`--auxiliary-color-2`, auxiliaryColor2)
  // getLess(primaryColor)
}

export default changeTheme
