const api = {
  // =====================通用======================
  getSiteConfig: '/sites/config', // 站点信息
  getSmartData: '/sites/smart', // 广告位信息
  articles: '/sites/article', // 站点资讯
  articleView: '/sites/article-view', // 站点资讯详情
  /** 查询人员证书详情 */
  getCertificate: '/home/search-cert',
  getCityList: '/home/city', // 获取城市列表
  getProvinceList: '/home/publicity-index', // 省级题库列表
  downloadFile: '/home/download', // 下载省级题库附件
  getDownloadRate: '/home/download-rate', // 下载进度
  getCustomList: '/home/custom-index', // 自建题库列表
  getProfessionClass: '/home/class', // 获取职业工种等级下拉列表
  getCommonCategories: 'common-categories', // 获取类型下拉列表

  //=====================评审范围======================
  categoriesClass: '/profession-category-commons/class', // 考评工种
  categories: '/category-lists', // 分类
  expertDeclaration: '/expert-declaration', // 提交
}
export default api
